import OnDemand2 from '@@src/@types/OnDemand2';
import { generateFullUrlFromLinkProps } from '@@src/routes';
import { Person } from '@@src/transformers/CatalogApiCommonTransformer';
import SeasonStructuredData from '@@src/utils/StructuredData/SeasonStructuredData';
import OnDemand from '@@types/OnDemand';

export default class SeriesStructuredData {
  static generate(series: Partial<OnDemand.TvSeries> | OnDemand2.CollectionItemSeries, language: string) {
    const seriesUrl = generateFullUrlFromLinkProps(series.route, language);

    let genre: string[] | string | null;

    if ('genres' in series) {
      [genre] = series.genres;
    }

    let classification: OnDemand.Classification | OnDemand2.Classification | null;

    if ('classification' in series) {
      classification = series.classification;
    } else if ('classificationId' in series) {
      classification = series.classificationId;
    }

    let numberOfSeasons: number;
    if ('numberOfSeasons' in series) {
      numberOfSeasons = series.numberOfSeasons;
    } else if ('seasonCount' in series) {
      numberOfSeasons = series.seasonCount;
    }

    let imageId: string;
    if ('odImageId' in series) {
      imageId = series.odImageId;
    } else if ('imageId' in series) {
      imageId = series.imageId;
    }

    let inLanguage: string[];
    if ('languages' in series) {
      inLanguage = series.languages;
    } else if ('language' in series) {
      inLanguage = [series.language];
    } else {
      inLanguage = ['English'];
    }

    const result = {
      '@type': 'TVSeries',
      '@id': seriesUrl,
      name: series.title,
      description: series.description,
      genre,
      contentRating: classification,
      url: seriesUrl,
      numberOfSeasons,
      image: imageId,
      inLanguage,
    };

    if (series?.cast?.length) {
      result['director'] = series.directors.map((director) => {
        return {
          '@type': 'Person',
          name: director,
        };
      });

      result['actor'] = series.cast.map((actor: Person | string) => {
        if (typeof actor === 'string') {
          return {
            '@type': 'Person',
            name: actor,
          };
        }

        return {
          '@type': 'Person',
          name: actor.name,
        };
      });
    }

    if (series?.seasons?.length) {
      result['containsSeason'] = series.seasons.map((season) => {
        return SeasonStructuredData.generate({ ...season, route: series.route }, language);
      });
    }

    if (series.countries && series.availability) {
      result['releasedEvent'] = {
        '@type': 'PublicationEvent',
        name: series.title,
        location: {
          '@type': 'Country',
          name: series.countries,
        },
        startDate: series.availability.availabilityStarts,
        endDate: series.availability.availabilityEnds,
      };
    }

    return result;
  }
}
